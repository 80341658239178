/** @format */
import { Link } from 'react-router-dom';

function Footer() {
	return (
		<footer>
			<nav className="wrapper">
				<p>
					<Link to={'/cgu'}>Conditions Générales d'Utilisation</Link>
				</p>
				<p>
					<Link to={'/politique-confidentialite'}>
						Politique de Confidentialité
					</Link>
				</p>
				<p>
					<Link to={'/contact'}>Contact</Link>
				</p>
				<p>Copyright © 2021 DenTaTête</p>
			</nav>
		</footer>
	);
}

export default Footer;
