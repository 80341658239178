/** @format */
import React, { useEffect } from 'react';

function CGU(props) {
	useEffect(() => {
		window.scrollTo(0, 0);
	});
	useEffect(() => {
		document.title = "Conditions Générales d'Utilisation - DenTaTête";
	});
	return (
		<article className="wrapper">
			<div className="recos">
				<h1>Conditions Générales d'Utilisation</h1>
				<h2>Mentions Légales</h2>
				<h3>Editeur</h3>
				<p>
					L’édition du site DenTaTête est assurée par Sami EL KATEB
					domicilié au 6 Rue François Massé, 06400 Nice, France.
				</p>
				<p>Téléphone : 06 95 13 79 75</p>
				<p>Courriel : sami.elkateb@dentatete.fr</p>
				<h3>Hébergeur</h3>
				<p>La Plateforme est hébergée par 1 &amp; 1 IONOS SARL : </p>
				<p>https://www.ionos.fr/terms-gtc/terms-imprint</p>
				<p>7 Place de la Gare, 57200 Sarreguemines, France</p>
				<p>Téléphone : 0970 808 911</p>
				<p>Courriel : info@ionos.fr</p>
				<h2>Article 1: Objet</h2>
				<p>
					Les présentes Conditions Générales d'Utilisation (ci-après
					dénommé « CGU »). ont pour objet l'encadrement juridique des
					modalités de mise à disposition du site www.dentatete.fr
					(ci-après dénommé « Site ») et de l'application mobile
					DenTaTête (ci-après dénommé « Application ») fournis par
					l'Éditeur. Les CGU définissent les conditions d’accès et
					d’utilisation des services du Site et de l'Application par
					l'Utilisateur.
				</p>
				<p>
					Les présentes CGU sont accessibles sur le Site, en pied de
					page, à la rubrique Conditions Générales d'Utilisation.
				</p>
				<p>
					Le Site et l'Application permettent l'accès à un espace
					numérique ci-après dénommé « Plateforme ».
				</p>
				<p>
					Constituant le contrat entre l'Éditeur et l’Utilisateur,
					l’accès à la Plateforme doit être précédé de l’acceptation
					de ces CGU. L’accès à cette Plateforme signifie
					l’acceptation des présentes CGU.
				</p>
				<h2>
					Article 2: Acceptation des Conditions Générales
					d'Utilisation
				</h2>
				<p>
					En acceptant ces Conditions Générales d'Utilisation,
					l'utilisateur reconnait:
				</p>
				<ul>
					<li>
						avoir pris connaissance présentes Conditions Générales
						d’Utilisation
					</li>
					<li>être majeur au jour de l'acceptation des CGU</li>
					<li>
						disposer de la capacité juridique lui permettant de
						s'engager au titre des présentes Conditions Générales
						d'Utilisation
					</li>
					<li>
						être un professionnel de la santé dentaire (chirurgien
						dentiste, étudiant en chirurgie dentaire)
					</li>
				</ul>
				<h2>Article 3: Accès</h2>
				<p>
					La Plateforme permet d’accéder à des fiches à visée
					d'information (ci-après dénommées « Contenu »). Celles-ci
					incluent entre autre :
				</p>
				<ul>
					<li>Des fiches cliniques synthétiques;</li>
					<li>Des fiches de rappel de protocoles opératoires;</li>
					<li>
						Des listes d'instruments regroupées en plateaux
						techniques;
					</li>
					<li>
						Des fiches synthétiques d'information sur les
						biomatériaux;
					</li>
				</ul>
				<p>
					Le site est accessible gratuitement en tout lieu à tout
					Utilisateur ayant un accès à Internet. Tous les frais
					supportés par l'Utilisateur pour accéder au service
					(matériel informatique, logiciels, connexion Internet, etc.)
					sont à sa charge.
				</p>
				<p>
					L’Utilisateur non membre n'a pas accès au Contenu. Pour
					cela, il doit s’inscrire en remplissant le formulaire et
					souscrire un abonnement mensuel ou annuel selon le choix de
					l’Utilisateur. En acceptant de s’inscrire aux services
					réservés, l’Utilisateur membre s’engage à fournir des
					informations sincères et exactes concernant son état civil
					et ses coordonnées, notamment son adresse email.
				</p>
				<p>
					Pour accéder aux services, l’Utilisateur doit ensuite
					s'identifier à l'aide de son identifiant et de son mot de
					passe qui lui seront communiqués après son inscription.
				</p>
				<p>
					Tout Utilisateur membre inscrit pourra également solliciter
					sa désinscription contactant l'Éditeur messagerie
					électronique à l’adresse email: contact@dentatete.fr{' '}
				</p>
				<p>
					Tout événement dû à un cas de force majeure ayant pour
					conséquence un dysfonctionnement du site ou serveur et sous
					réserve de toute interruption ou modification en cas de
					maintenance, n'engage pas la responsabilité de l'Éditeur.
					Dans ces cas, l’Utilisateur accepte ainsi ne pas tenir
					rigueur à l’éditeur de toute interruption ou suspension de
					service, même sans préavis.
				</p>
				<p>
					L'Utilisateur a la possibilité de contacter le site par
					messagerie électronique à l’adresse email:
					contact@dentatete.fr
				</p>
				<h2>Article 4: Opposabilité</h2>
				<p>
					Les présentes Conditions Générales d’Utilisation sont
					opposables dès la première connexion à la Plateforme.
				</p>
				<p>
					L’Editeur se réserve le droit d’apporter aux présentes
					Conditions Générales d’Utilisation toutes les modifications
					qu’il jugera nécessaires et utiles.
				</p>
				<p>
					L’Editeur informera l’Utilisateur de l’existence et de
					l’entrée en vigueur de nouvelles conditions générales
					d’utilisation par une information sur la Plateforme.
				</p>
				<p>
					L’Utilisateur informé dans les conditions ci-dessus dispose
					de la faculté d’accepter les nouvelles Conditions Générales
					d’Utilisation ou, s’il refuse les nouvelles Conditions
					Générales d’Utilisation, de fermer son Compte dans les
					conditions prévues à l’article 14 ci-dessous. L’Utilisateur
					reste responsable de toute utilisation de son Compte
					antérieure à la fermeture de celui-ci.
				</p>
				<h2>Article 5: Création d'un compte</h2>
				<p>
					Les Utilisateurs peuvent créer un Compte sur la Plateforme
					dès lors qu’ils ont un accès au réseau Internet avec un
					débit suffisant, une adresse électronique et qu’ils
					justifient de leur statut.
				</p>
				<p>
					L’Utilisateur souhaitant créer un Compte est alors invité à
					saisir son prénom, son nom, une adresse de courrier
					électronique et un mot de passe. Le mot de passe constitue
					la garantie de la confidentialité des informations contenues
					dans le Compte. Un message est alors envoyé à son adresse de
					courrier électronique pour la confirmer et permettre la
					création d’un Compte.
				</p>
				<p>
					Pour valider son compte, l’Utilisateur devra fournir la
					copie scannée d'une pièce d'identité, un justificatif
					relatif à son statut (certificat de scolarité pour les
					étudiants et numéro RPPS pour les professionnels), certifier
					sur l’honneur être étudiant en chirurgie dentaire ou un
					professionnel de la chirurgie dentaire et accepter les
					présentes Conditions Générales d’Utilisation.
				</p>
				<p>
					L’Utilisateur s’interdit de transmettre ou de le communiquer
					son mot de passe à un tiers. A défaut, l’Editeur ne pourra
					être tenu pour responsable des accès non autorisés au Compte
					d’un Utilisateur.
				</p>
				<p>
					L’Utilisateur s’engage à informer immédiatement l’Editeur si
					un tiers a eu connaissance de ses identifiants et à prendre
					des mesures immédiates et efficaces, telles que par exemple
					changer de mot de passe. L’Editeur ne peut être tenu
					responsable pour toute utilisation frauduleuse du Compte
					d’un Utilisateur.
				</p>
				<h2>Article 6: Souscription à un abonnement</h2>
				<p>
					Les Utilisateurs peuvent s’abonner à la Plateforme dès lors
					qu'ils possèdent un Compte. La souscription à l'abonnement
					se fait à partir de l'appareil mobile par l'intermédiaire de
					l'AppStore sur les appareils utilisant le système
					d'exploitation iOS ou du PlayStore usr les appareils
					utilisant le système d'exploitation Android.
				</p>
				<p>
					L'Utilisateur devra choisir les modalités de l’Abonnement
					auquel il souhaite souscrire.
				</p>
				<h2>
					Article 7: Prix de l'abonnement et Modalités de Paiement
				</h2>
				<p>
					Le prix de l’Abonnement correspond au forfait choisi lors de
					l’inscription.
				</p>
				<p>
					L’Abonnement peut commencer par un essai gratuit. Dans ce
					cas, les frais relatifs à l’Abonnement choisi par
					l’Utilisateur ne seront prélevés qu’à l’issue de la période
					d’essai gratuite et pour le mois suivant cette période, sauf
					si l’Utilisateur annule son Abonnement avant la fin de la
					période d’essai gratuit.
				</p>
				<p>
					L’Editeur peut modifier les prix de l’Abonnement librement.
					Néanmoins, les modifications du prix de l’Abonnement ne
					seront pas applicables aux périodes entamées et en tout état
					de cause ne seront applicables qu’après un délai minimum de
					trente (30) jours après réception par l’Utilisateur d’une
					notification de l’Editeur par email.
				</p>
				<p>
					Le paiement se fera par carte bancaire par l’intermédiaire
					des services sécurisés de l'AppStore sur les appareils
					mobiles utilisant le système d'exploitation iOS ou du
					PlayStore usr les appareils mobiles utilisant le système
					d'exploitation Android.
				</p>
				<h2>Article 8: Collecte des données</h2>
				<p>
					Pour la création du compte de l’Utilisateur, la collecte des
					informations au moment de l’inscription sur le site est
					nécessaire et obligatoire. Conformément à la loi n°78-17 du
					6 janvier relative à l’informatique, aux fichiers et aux
					libertés, la collecte et le traitement d’informations
					personnelles s’effectuent dans le respect de la vie privée.
				</p>
				<p>
					Suivant la loi Informatique et Libertés en date du 6 janvier
					1978, articles 39 et 40, l’Utilisateur dispose du droit
					d’accéder, de rectifier, de supprimer et d’opposer ses
					données personnelles. L’exercice de ce droit s’effectue
					par :
				</p>
				<ul>
					<li>
						Par messagerie électronique à l’adresse email:
						contact@dentatete.fr
					</li>
					<li>Depuis son espace membre</li>
				</ul>
				<h2>Article 9: Propriété intellectuelle</h2>
				<p>
					Les marques, logos ainsi que les contenus de la plateforme
					(illustrations graphiques, textes…) sont protégés par le
					Code de la propriété intellectuelle et par le droit
					d’auteur.
				</p>
				<p>
					La marque DenTaTête est une marque déposée par l'Éditeur.
					Toute représentation et/ou reproduction et/ou exploitation
					partielle ou totale de cette marque, de quelque nature que
					ce soit, est totalement prohibée.
				</p>
				<p>
					L'Utilisateur doit solliciter l'autorisation préalable du
					site pour toute reproduction, publication, copie des
					différents contenus. Il s'engage à une utilisation des
					contenus du site dans un cadre strictement privé, toute
					utilisation à des fins commerciales et publicitaires est
					strictement interdite.
				</p>
				<p>
					Toute représentation totale ou partielle de ce site par
					quelque procédé que ce soit, sans l’autorisation expresse de
					l’exploitant du site Internet constituerait une contrefaçon
					sanctionnée par l’article L 335-2 et suivants du Code de la
					propriété intellectuelle.
				</p>
				<p>
					Il est rappelé conformément à l’article L122-5 du Code de
					propriété intellectuelle que l’Utilisateur qui reproduit,
					copie ou publie le contenu protégé doit citer l’auteur et sa
					source.
				</p>
				<h2>Article 10: Responsabilité de l'Éditeur</h2>
				<p>
					La Plateforme ne fournis aucun service de consultation
					médical ou de consultation en matière de santé, de conseil
					médical ou de conseil en matière de santé, ni aucun service
					ou produit médical ou se rapportant à la santé.
				</p>
				<p>
					Les informations diffusées sur la Plateforme sont la
					synthèse d'informations de sources réputées fiables mais la
					Plateforme ne garantit pas quelles soit exemptes de défauts,
					d’erreurs ou d’omissions.
				</p>
				<p>
					Il est rappelé que le Contenu de la Plateforme sont des
					fiches synthétiques de rappels. L’Utilisateur n’est en aucun
					cas tenu de mettre en application les informations
					présentées. L'Utilisateur ne doit pas appliquer de
					protocoles qu'il ne maitrise pas. S’il choisit de les
					appliquer, l’Editeur ne pourra pas être tenu responsable de
					leur mauvaise application par l’Utilisateur ou des dommages
					subis par les tiers lors de la mise en application de
					ceux-ci.
				</p>
				<p>
					En outre, la responsabilité de l’Editeur ne saurait être
					engagée pour tout dommage subi par l’Utilisateur ou par un
					tiers résultant directement ou indirectement du non-respect
					par l’Utilisateur de l’une quelconque de ses obligations.
				</p>
				<h2>
					Article 11: Obligation et Responsabilité de l'Utilisateur
				</h2>
				<p>
					L’Utilisateur est responsable de ses actions sur la
					Plateforme. Il s’engage à utiliser la Plateforme de façon
					loyale, dans le respect des présentes Conditions Générales
					d’Utilisation en respectant les législations et les
					réglementations en vigueur, à l’ordre public, aux bonnes
					mœurs et aux droits des tiers.
				</p>
				<p>
					L’Utilisateur s’interdit de télécharger le Contenu mis à sa
					disposition sur la Plateforme et procéder à des captures
					d’écran lorsqu’il navigue sur la Plateforme.
				</p>
				<p>
					Tout usage illégal ou de manière générale non autorisé des
					services de la Plateforme entraînera la suppression
					immédiate du Compte de l’Utilisateur, sans préjudice pour
					l’Editeur de toute action en justice aux fins
					d’indemnisation. Enfin, outre la condamnation éventuelle au
					paiement de dommages et intérêts, l’Utilisateur pourrait le
					cas échéant encourir à titre personnel, les sanctions
					pénales spécifiques aux contenus litigieux (peines
					d’emprisonnement et d’amende).
				</p>
				<h2>
					Article 12: Suspension et Suppression de l'accès au Compte
				</h2>
				L’Editeur se réserve le droit de suspendre et/ou supprimer le
				compte de tout Utilisateur qui ne se conforme pas aux présentes
				Conditions Générales d’Utilisation et/ou qui enfreint la loi.
				<h2>Article 13: Durée et Résiliation</h2>
				<p>
					S’agissant de l’Abonnement, l’Utilisateur a la possibilité
					de souscrire un Abonnement mensuel ou annuel. Les
					Abonnements entrent en vigueur le jour de leur souscription.
				</p>
				<p>
					Les Abonnements souscrits par l'intermédiaire de sociétés
					tiers, notamment sur l'AppStore de Apple et sur le PlayStore
					de Google ne sont pas du ressort de l'Éditeur. La suspension
					de ces abonnements est à réaliser sur la plateforme sur
					laquelle ils ont été souscrit.
				</p>
				<p>
					L’Abonnement mensuel est tacitement reconductible par
					périodes successives d’un (1) mois, sauf dénonciation par le
					Client sept (7) jours avant l’expiration de la période en
					cours par courriel à l’adresse contact@dentatete.fr
				</p>
				<p>
					L’Abonnement annuel est tacitement reconductible, par
					périodes successives de douze (12) mois, sauf dénonciation
					par l’Utilisateur deux (2) mois avant l’expiration de la
					période en cours par courriel à
					l’adresse contact@dentatete.fr
				</p>
				<p>
					L’Editeur se réserve le droit de prendre toutes les mesures
					nécessaires, y compris la suppression d’un Compte, sans
					devoir donner de motifs, ni préavis, ni formalités, ni
					indemnités au profit de l’Utilisateur, dans le cas d’une
					décision judiciaire l’y contraignant, d’événements de force
					majeure, de manquement aux présentes Conditions Générales
					d’Utilisation ou encore en cas de suspicion de fraude.
					L’Editeur avertira par tout moyen approprié l’Utilisateur de
					la mesure prise et des raisons qui l’ont amené à prendre une
					telle décision. Le Compte de l’Utilisateur ne sera plus
					accessible à compter de la date de résiliation et l’accès à
					la Plateforme sera immédiatement suspendu.
				</p>
				<p>
					Une telle résiliation interviendra sans préjudice de tous
					les dommages et intérêts qui pourraient être réclamés en
					réparation des préjudices subis du fait de tels manquements.
				</p>
				<p>
					Au moment de toute résiliation, les droits et les licences
					accordés à l’Utilisateur seront résiliés et l’Utilisateur
					devra cesser toute utilisation de la Plateforme.
					L’Utilisateur n’aura droit à aucune indemnité.
				</p>
				<h2>Article 14: Liens hypertextes</h2>
				<p>
					Des liens hypertextes peuvent être présents sur la
					Plateforme. L’Utilisateur est informé qu’en cliquant sur ces
					liens, il sortira de la Plateforme. L'Éditeur n’a pas de
					contrôle sur les pages web sur lesquelles aboutissent ces
					liens et ne saurait, en aucun cas, être responsable de leur
					contenu.
				</p>
				<h2>Article 15: Cookies</h2>
				<p>
					L’Utilisateur est informé que lors de ses visites sur le
					Site, un cookie peut s’installer automatiquement sur son
					logiciel de navigation.
				</p>
				<p>
					Les cookies sont de petits fichiers stockés temporairement
					sur le disque dur de l’ordinateur de l’Utilisateur par son
					navigateur et qui sont nécessaires à l’utilisation du Site.
					Certains cookies expirent à la fin de la visite de
					l’Utilisateur, d’autres restent.
				</p>
				<p>
					L’information contenue dans les cookies est utilisée pour
					améliorer le Site.
				</p>
				<p>En naviguant sur le site, L’Utilisateur les accepte.</p>
				<p>
					L’Utilisateur doit toutefois donner son consentement quant à
					l’utilisation de certains cookies.
				</p>
				<p>
					A défaut d’acceptation, l’Utilisateur est informé que
					certaines fonctionnalités ou pages risquent de lui être
					refusées ou de ne pas fonctionner.
				</p>
				<p>
					L’Utilisateur pourra désactiver ces cookies par
					l’intermédiaire des paramètres figurant au sein de son
					logiciel de navigation.
				</p>
				<h2>Article 16: Durée du contrat</h2>
				<p>
					Le présent contrat est valable pour une durée indéterminée.
					Le début de l’utilisation des services du site marque
					l’application du contrat à l’égard de l’Utilisateur.
				</p>
				<h2>Article 17: Dispositions Générales</h2>
				<h3>Intégralité</h3>
				<p>
					Si une quelconque des stipulations des présentes Conditions
					Générales d’Utilisation (en totalité ou en partie) s’avérait
					être illégale, invalide ou inapplicable, les autres
					dispositions demeureraient pleinement en vigueur.
				</p>
				<h3>Non-renonciation</h3>
				<p>
					L’absence d’exercice par les parties des droits qui leur
					sont reconnus par les présentes ne pourra en aucun cas être
					interprétée comme une renonciation à faire valoir lesdits
					droits.
				</p>
				<h3>Convention de preuve</h3>
				<p>
					L’acceptation des Conditions Générales d’Utilisation par
					voie électronique a, entre les parties, la même valeur
					probante que l’accord sur support papier.
				</p>
				<p>
					Les registres informatisés conservés par l’Editeur et/ou ses
					prestataires dans des conditions raisonnables de sécurité,
					sont considérés comme les preuves des communications
					intervenues entre les parties.
				</p>
				<p>
					L’archivage des données est effectué sur un support fiable
					et durable pouvant être produit à titre de preuve.
				</p>
				<h2>Article 18: Droit applicable et juridiction compétente</h2>
				<p>
					La législation française s'applique au présent contrat. En
					cas d'absence de résolution amiable d'un litige né entre les
					parties, les tribunaux français seront seuls compétents pour
					en connaître.
				</p>
				<p>
					Pour toute question relative à l’application des présentes
					CGU, vous pouvez joindre l’éditeur aux coordonnées inscrites
					dans les mentions légales.
				</p>
			</div>
		</article>
	);
}

export default CGU;
