/** @format */

import Button from '../Buttons/Button';
import Input from '../Form/Input';
import Logo from '../Standard/Logo';
import { SendResetCodeAPI } from '../../API/Dentatete';
import React from 'react';

class ResetPassword extends React.Component {
	constructor(props) {
		super(props);
		this.email = '';
		this.state = { sent: false };
	}
	componentDidMount() {
		window.scrollTo(0, 0);
		document.title = 'Réinitialisation du mot de passe - DenTaTête';
	}

	handleInputChange = (event) => {
		const target = event.target;
		const value = target.value;
		const name = target.name;
		this[name] = value;
	};
	_submit = (event) => {
		event?.preventDefault();
		const email = this.email;
		const formData = { email };
		SendResetCodeAPI(formData).then(() => {
			this.setState({
				sent: true,
			});
		});
	};
	render() {
		if (this.state.sent) {
			return (
				<section className={'auth'}>
					<div className={'wrapper'}>
						<form>
							<Logo />
							<h1>Réinitialisation</h1>
							<p>
								Vous recevrez un email sur l'adresse que vous
								avez fournie si elle est associée à un compte
								existant.
							</p>
						</form>
					</div>
				</section>
			);
		}
		return (
			<section className={'auth'}>
				<div className={'wrapper'}>
					<form onSubmit={this._submit}>
						<Logo />
						<h1>Réinitialiser le mot de passe</h1>
						<Input
							type="email"
							onChange={this.handleInputChange}
							autoComplete="email"
						/>
						<Button
							text={'Réinitialiser'}
							callback={this._submit}
						/>
					</form>
				</div>
			</section>
		);
	}
}

export default ResetPassword;
