/** @format */

import { FaArrowRight } from 'react-icons/fa';
import { Link, useRouteMatch } from 'react-router-dom';
function LeftNavButton(props) {
	let { url } = useRouteMatch();
	const { name, distant_path } = props.item;
	const { disabled } = props;
	let className = 'left-nav-btn';
	if (disabled) className += ' disabled';
	if (url && disabled) {
		return (
			<div className={className}>
				<div>{name}</div>
				<div className={'arrow'}>
					<FaArrowRight />
				</div>
			</div>
		);
	}
	if (distant_path) {
		return (
			<Link to={`${url}/` + distant_path}>
				<div>{name}</div>
				<div className={'arrow'}>
					<FaArrowRight />
				</div>
			</Link>
		);
	}
	return <button className={className}>{name}</button>;
}

export default LeftNavButton;
