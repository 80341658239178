/** @format */

function Checkbox(props) {
	const { type, onChange } = props;
	//let value = false;
	switch (type) {
		case 'politique':
			return (
				<label className={'checkbox'}>
					<input
						type={'checkbox'}
						name="politique"
						onChange={(event) => {
							onChange(event);
						}}
					/>
					<p>
						J'ai lu et j'accepte la{' '}
						<a href="/politique-confidentialite" target="_blank">
							Politique de Confidentialité
						</a>
						.
					</p>
				</label>
			);
		case 'cgu':
			return (
				<label className={'checkbox'}>
					<input
						type={'checkbox'}
						name="cgu"
						onChange={(event) => {
							onChange(event);
						}}
					/>
					<p>
						J'ai lu et j'accepte les{' '}
						<a href="/cgu" target="_blank">
							Conditions Générales d'Utilisation
						</a>
						.
					</p>
				</label>
			);
		case 'etudiant':
			return (
				<label className={'checkbox'}>
					<input
						type={'radio'}
						name="statut"
						value={'student'}
						onChange={(event) => {
							onChange(event);
						}}
					/>
					<p>
						Je certifie sur l'honneur être un étudiant en
						odontologie.
					</p>
				</label>
			);
		case 'dentiste':
			return (
				<label className={'checkbox'}>
					<input
						type={'radio'}
						name="statut"
						value={'dentist'}
						onChange={(event) => {
							onChange(event);
						}}
					/>
					<p>
						Je certifie sur l'honneur être un chirurgien-dentiste.
					</p>
				</label>
			);

		default:
			return (
				<label className={'checkbox'}>
					<input
						type={'checkbox'}
						name="etudiant"
						onChange={(event) => {
							onChange(event);
						}}
					/>
					<p></p>
				</label>
			);
	}
}

export default Checkbox;
