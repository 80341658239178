/** @format */

export async function GetTree(props) {
	let { ancestry, id } = props;
	let data = new FormData();
	data.append('ancestry', ancestry);
	data.append('id', id);
	const url = 'https://db.dentatete.fr/API/GetTree';
	const requestOptions = {
		method: 'POST',
		body: data,
	};
	return fetch(url, requestOptions)
		.then((response) => response.json())
		.catch((error) => console.log(error));
}

export async function GetFiche(path) {
	let data = new FormData();
	data.append('url', path);
	const url = 'https://db.dentatete.fr/site/API/GetFiche';
	const requestOptions = {
		method: 'POST',
		body: data,
	};
	return fetch(url, requestOptions)
		.then((response) => response.json())
		.catch((error) => console.log(error));
}

export async function LoginAPI(formData) {
	const { email, password } = formData;
	let data = new FormData();
	data.append('email', email);
	data.append('password', password);
	const url = 'https://db.dentatete.fr/site/API/Login';
	const requestOptions = {
		method: 'POST',
		body: data,
	};
	return fetch(url, requestOptions)
		.then((response) => response.json())
		.catch((error) => console.log(error));
}

export async function SignupAPI(formData) {
	const { email, password, firstName, lastName, statut } = formData;
	let data = new FormData();
	data.append('email', email);
	data.append('firstName', firstName);
	data.append('lastName', lastName);
	data.append('statut', statut);
	data.append('password', password);
	const url = 'https://db.dentatete.fr/site/API/Signup';
	const requestOptions = {
		method: 'POST',
		body: data,
	};
	return fetch(url, requestOptions)
		.then((response) => response.json())
		.catch((error) => console.log(error));
}

export async function ContactAPI(formData) {
	const { email, firstName, object, message } = formData;
	let data = new FormData();
	data.append('email', email);
	data.append('firstName', firstName);
	data.append('object', object);
	data.append('message', message);
	const url = 'https://db.dentatete.fr/site/API/Contact';
	const requestOptions = {
		method: 'POST',
		body: data,
	};
	return fetch(url, requestOptions)
		.then((response) => response.json())
		.catch((error) => console.log(error));
}

export async function ConfirmEmailAPI(formData) {
	const { email, code } = formData;
	let data = new FormData();
	data.append('code', code);
	data.append('email', email);
	const url = 'https://db.dentatete.fr/site/API/ConfirmEmail';
	const requestOptions = {
		method: 'POST',
		body: data,
	};
	return fetch(url, requestOptions)
		.then((response) => response.json())
		.catch((error) => console.log(error));
}

export async function ChangeEmailAPI(formData) {
	const { email, code } = formData;
	let data = new FormData();
	data.append('code', code);
	data.append('email', email);
	const url = 'https://db.dentatete.fr/site/API/ChangeEmail';
	const requestOptions = {
		method: 'POST',
		body: data,
	};
	return fetch(url, requestOptions)
		.then((response) => response.json())
		.catch((error) => console.log(error));
}

export async function SendResetCodeAPI(formData) {
	const { email } = formData;
	let data = new FormData();
	data.append('email', email);
	const url = 'https://db.dentatete.fr/site/API/sendCode';
	const requestOptions = {
		method: 'POST',
		body: data,
	};
	return fetch(url, requestOptions)
		.then((response) => response.json())
		.catch((error) => console.log(error));
}
export async function ResetPasswordAPI(formData) {
	const { email, code, password } = formData;
	let data = new FormData();
	data.append('code', code);
	data.append('email', email);
	data.append('password', password);
	const url = 'https://db.dentatete.fr/site/API/resetPassword';
	const requestOptions = {
		method: 'POST',
		body: data,
	};
	return fetch(url, requestOptions)
		.then((response) => response.json())
		.catch((error) => console.log(error));
}

export async function Logout(formData) {
	const { email } = formData;
	//const { email, password } = formData
	let data = new FormData();
	data.append('email', email);
	const url = 'https://db.dentatete.fr/API/GetFiche';
	const requestOptions = {
		method: 'POST',
		body: data,
	};
	return fetch(url, requestOptions)
		.then((response) => response.json())
		.catch((error) => console.log(error));
}
