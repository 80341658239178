/** @format */

import Button from '../Buttons/Button';
import Input from '../Form/Input';
import Logo from '../Standard/Logo';
import { ResetPasswordAPI } from '../../API/Dentatete';
import Error from '../Others/Error';
import { withRouter } from 'react-router';
import React from 'react';

class ResetPassword extends React.Component {
	constructor(props) {
		super(props);
		this.password = '';
		const { code, email } = this.props.match.params;
		this.code = code;
		this.email = email;
		this.state = { error: false };
	}
	componentDidMount() {
		window.scrollTo(0, 0);
		document.title = 'Changement du mot de passe - DenTaTête';
	}

	handleInputChange = (event) => {
		const target = event.target;
		const value = target.value;
		const name = target.name;
		this[name] = value;
	};
	_submit = (event) => {
		event?.preventDefault();
		const password = this.password;
		const code = this.code;
		const email = this.email;
		if (!password || !code || !email) return;
		const formData = { password, code, email };
		ResetPasswordAPI(formData).then((data) => {
			this.setState({
				error: data.error,
			});
		});
	};
	render() {
		if (this.state.error === 'none') {
			return (
				<section className={'auth'}>
					<div className={'wrapper'}>
						<form>
							<Logo />
							<h1>Réinitialisation</h1>
							<p>Mot de passe modifié</p>
						</form>
					</div>
				</section>
			);
		}
		return (
			<section className={'auth'}>
				<div className={'wrapper'}>
					<form onSubmit={this._submit}>
						<Logo />
						<h1>Réinitialiser le mot de passe</h1>
						{this.state.error ? (
							<Error error={this.state.error} />
						) : null}
						<Input
							type="password"
							onChange={this.handleInputChange}
							autoComplete="new-password"
						/>
						<Button
							text={'Modifier le mot de passe'}
							callback={this._submit}
						/>
					</form>
				</div>
			</section>
		);
	}
}

export default withRouter(ResetPassword);
