/** @format */

import { UserContext } from '../../Context/UserContext';
import Button from '../Buttons/Button';
import Input from '../Form/Input';
import { ContactAPI } from '../../API/Dentatete';
import React from 'react';

class Contact extends React.Component {
	constructor(props) {
		super(props);
		this.email = false;
		this.firstName = false;
		this.object = false;
		this.message = false;
		this.state = { disabled: 'disabled', step: 'start' };
		window.scrollTo(0, 0);
	}
	componentDidMount() {
		document.title = 'Contact - DenTaTête';
	}
	handleInputChange = (event) => {
		const target = event.target;
		const value = target.value;
		const name = target.name;
		this[name] = value;

		if (this.email && this.firstName && this.object && this.message) {
			this.setState({ disabled: '' });
		} else {
			this.setState({ disabled: 'disabled' });
		}
	};
	_checkSubmit = (event) => {
		event.preventDefault();
		this.setState({ disabled: 'disabled' });
		if (!this.email || this.email === '') return;
		if (!this.firstName || this.firstName === '') return;
		if (!this.object || this.object === '') return;
		if (!this.message || this.message === '') return;
		this._submit(event);
	};

	_submit = (event) => {
		const that = this;
		event.preventDefault();
		const email = this.email;
		const firstName = this.firstName;
		const object = this.object;
		const message = this.message;
		const formData = { email, firstName, object, message };
		// code the api and sending mail with confirmation mail
		ContactAPI(formData).then((data) => {
			if (data.error === 'none') {
				that.setState({ step: 'sent' });
				console.log('no error');
			} else {
				that.setState({ step: 'error' });
			}
		});
	};
	_sent = () => {
		return (
			<section className={'contact'}>
				<div className={'wrapper'}>
					<h1>Formulaire de contact</h1>
					<p className={'confirmation-message sent'}>
						Votre message a bien été envoyé. Vous recevrez un e-mail
						de confirmation dans quelques minutes.
					</p>
				</div>
			</section>
		);
	};
	_error = () => {
		return (
			<section className={'contact'}>
				<div className={'wrapper'}>
					<h1>Formulaire de contact</h1>
					<p className={'confirmation-message error'}>
						Une erreur s'est produite lors de l'envoi de votre
						message. Réessayez dans quelques minutes ou
						contactez-nous directement à l'adresse :
						support@dentatete.fr
					</p>
				</div>
			</section>
		);
	};
	_contactForm = () => {
		const userContext = this.context;
		const user = userContext.user;
		let firstName = '';
		let email = '';
		if (user) {
			firstName = user.firstName;
			email = user.email;
		}
		return (
			<section className={'contact'}>
				<div className={'wrapper'}>
					<h1>Formulaire de contact</h1>
					<form onSubmit={this._submit}>
						<div className={'input-wrapper'}>
							<div>
								<h2>Prénom</h2>
								<Input
									type="firstName"
									onChange={this.handleInputChange}
									value={firstName}
								/>
							</div>
							<div>
								<h2>E-mail</h2>
								<Input
									type="email"
									onChange={this.handleInputChange}
									value={email}
								/>
							</div>
						</div>
						<h2>Sujet</h2>
						<Input
							type="object"
							onChange={this.handleInputChange}
						/>
						<h2>Votre message</h2>
						<Input
							type="message"
							onChange={this.handleInputChange}
						/>
						<Button
							text={'Envoyer'}
							callback={this._checkSubmit}
							disabled={this.state.disabled}
						/>
					</form>
				</div>
			</section>
		);
	};
	render() {
		const step = this.state.step;
		switch (step) {
			case 'start':
				return this._contactForm();
			case 'error':
				return this._error();

			case 'sent':
				return this._sent();

			default:
				return this._contactForm();
		}
	}
}
Contact.contextType = UserContext;

export default Contact;
