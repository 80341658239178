import fiches from '../../Assets/fiches.svg'
import plateaux from '../../Assets/plateaux.svg'
import outils from '../../Assets/outils.svg'
import { GoListOrdered } from 'react-icons/go'
function iconParse(img, show) {
	let className = 'icon ' + show
	switch (img) {
		case 'fiche':
			return <img className={className} src={fiches} alt={'Fiches'} />
		case 'plateaux':
			return (
				<img
					className={className}
					src={plateaux}
					alt={'Plateaux Techniques'}
				/>
			)
		case 'outils':
			return <img className={className} src={outils} alt={'Outils'} />
		case 'none':
			return (
				<img
					className={'icon invisible'}
					src={outils}
					alt={'Icone invisible'}
				/>
			)
		case 'protocoles':
			return (
				<div className={'react-icon'}>
					<GoListOrdered />
				</div>
			)
		default:
			return <img className={'icon'} src={fiches} alt={'Fiches'} />
	}
}
function Feature(props) {
	const { title, text, img } = props

	return (
		<div className={'feature'}>
			{iconParse(img, 'hidden-sm')}
			<div>
				<h3>
					{' '}
					{iconParse(img, 'hidden-lg')}
					{title}
				</h3>
				<p>{text}</p>
			</div>
		</div>
	)
}

export default Feature
