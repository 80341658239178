/** @format */

import { UserContext } from '../../Context/UserContext';
import Button from '../Buttons/Button';
import React, { useEffect } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
function Header(props) {
	const [scrolled, setScrolled] = React.useState(false);
	const handleScroll = () => {
		const offset = window.scrollY;
		if (offset > 10) {
			setScrolled(true);
		} else {
			setScrolled(false);
		}
	};

	useEffect(() => {
		window.addEventListener('scroll', handleScroll);
	});
	let navbarClasses = [];
	if (scrolled) {
		navbarClasses.push('sticky');
	}
	return (
		<UserContext.Consumer>
			{(userContext) => (
				<header className={navbarClasses.join(' ')}>
					<nav className="wrapper">
						<span className="brand-name hidden-sm">DenTaTete</span>
						<HeaderLink
							to="/"
							activeOnlyWhenExact={true}
							label="Accueil"
						/>

						<HeaderLink
							to="/recommandations"
							label="Recommandations"
						/>
						<HeaderLink to="/fiches" label="Fiches" />
						{!userContext.user ? (
							<div className="authentification hidden-sm">
								<HeaderLink
									to="/connection"
									label="Se connecter"
									login="true"
								/>
								<HeaderLink
									to="/inscription"
									label="S'inscrire"
									button={true}
								/>
							</div>
						) : (
							<Button
								text={'Se Deconnecter'}
								callback={() => {
									console.log('userContext');
									userContext.logout();
								}}
								type={'clear hidden-sm'}
							/>
						)}
					</nav>
				</header>
			)}
		</UserContext.Consumer>
	);
}
function HeaderLink({ label, to, activeOnlyWhenExact, button, login }) {
	let match = useRouteMatch({
		path: to,
		exact: activeOnlyWhenExact,
	});
	let className = match ? 'active' : '';
	if (button) className += ' signup';
	if (login) className += ' login';

	return (
		<span className={className}>
			<Link to={to}>{label}</Link>
		</span>
	);
}

export default Header;
