import light from '../../Assets/light.svg'
function Separator(props) {
	const { text } = props
	return (
		<div className="separator">
			<img src={light} alt={'Tooth with a light to signal an idea'} />
			<h3>{text}</h3>
		</div>
	)
}

export default Separator
