import recos from '../Assets/recos'
import React, { useEffect } from 'react'
function Recommandations(props) {
	useEffect(() => {
		window.scrollTo(0, 0)
	})
	useEffect(() => {
		document.title = "Recommandations - DenTaTête";
	  });
	  
	const PrintRecos = (recos) => {
		if (recos.evaluation) {
			return (
				<>
					<h3>Recommandations</h3>
					<ul>
						{recos.recos.map((reco) => {
							return (
								<li key={reco.id}>
									<span className="date">{reco.date}</span>
									<a
										href={reco.link}
										target="_blank"
										rel="noopener noreferrer"
									>
										{reco.name}
									</a>
								</li>
							)
						})}
					</ul>
					<h3>Evaluations</h3>
					<ul>
						{recos.evaluation.map((evaluation) => {
							return (
								<li key={evaluation.id}>
									<span className="date">
										{evaluation.date}
									</span>
									<a
										href={evaluation.link}
										target="_blank"
										rel="noopener noreferrer"
									>
										{evaluation.name}
									</a>
								</li>
							)
						})}
					</ul>
				</>
			)
		}
		return (
			<ul>
				{recos.recos.map((reco) => {
					return (
						<li key={reco.id}>
							<span className="date">{reco.date}</span>
							<a
								href={reco.link}
								target="_blank"
								rel="noopener noreferrer"
							>
								{reco.name}
							</a>
						</li>
					)
				})}
			</ul>
		)
	}
	return (
		<article className="wrapper">
			<div className="recos">
				<h1>Recommandations</h1>
				{recos.map((item, index) => {
					return (
						<div key={index}>
							<h2>{item.entity}</h2>
							{PrintRecos(item)}
						</div>
					)
				})}
			</div>
		</article>
	)
}

export default Recommandations
