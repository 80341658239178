/** @format */

import LeftNavButton from '../Buttons/LeftNavButton';
import menu from '../../Assets/menu/menu';
import {
	prothese_complete,
	dentisterie_restauratrice,
	endodontie,
	urgences_endodontie,
	urgences_parodontie,
	urgences_chirurgie_bucale,
	odontologie_pediatrique,
	risques_cardio_vasculaire,
	risques_endocriniens,
	risques_traitements_medicaux,
	radiologie,
	algies_faciales,
	risques_hematologiques
} from '../../Assets/menu/index';
import { useSelector } from 'react-redux';
import '../../Styles/components/_leftnav.scss';
function LeftNav(props) {
	let { showLesson, show } = props;
	let menus = {
		prothese_complete,
		dentisterie_restauratrice,
		endodontie,
		urgences_endodontie,
		urgences_parodontie,
		urgences_chirurgie_bucale,
		odontologie_pediatrique,
		risques_cardio_vasculaire,
		risques_endocriniens,
		risques_traitements_medicaux,
		radiologie,
		algies_faciales,
		risques_hematologiques
	};
	//const loggedIn = useSelector((state) => state.loggedIn.loggedIn)
	const access = useSelector((state) => state.Access.access);
	const showTree = (tree) => {
		if (!tree || tree.length === 0) return null;
		return tree.map((subTree) => {
			if (subTree?.children?.length > 0) {
				if (subTree.access > access) {
					return (
						<li key={subTree.id}>
							<LeftNavButton
								item={subTree}
								showLesson={showLesson}
								disabled={true}
							/>
						</li>
					);
				}
				return (
					<li key={subTree.id}>
						<LeftNavButton
							item={subTree}
							showLesson={showLesson}
							disabled={false}
						/>
						<ul>{showTree(subTree.children)}</ul>
					</li>
				);
			}
			if (subTree.child) {
				if (subTree.access > access) {
					return (
						<li key={subTree.id}>
							<LeftNavButton
								item={subTree}
								showLesson={showLesson}
								disabled={true}
							/>
						</li>
					);
				}
				return (
					<li key={subTree.id}>
						<LeftNavButton
							item={subTree}
							showLesson={showLesson}
							disabled={false}
						/>
						<ul>{showTree(menus[subTree.child])}</ul>
					</li>
				);
			}
			if (subTree.distant_path) {
				if (subTree.access > access) {
					return (
						<li key={subTree.id}>
							<LeftNavButton item={subTree} disabled={true} />
						</li>
					);
				}
				return (
					<li key={subTree.id}>
						<LeftNavButton
							item={subTree}
							showLesson={showLesson}
							disabled={false}
						/>
					</li>
				);
			}
		});
	};
	let className = 'left-nav ' + show;
	return (
		<nav className={className}>
			<ul>{showTree(menu)}</ul>
		</nav>
	);
}

export default LeftNav;
