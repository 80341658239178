import '../../Styles/components/_toggleSwitch.scss'
import React from 'react'

function ToggleSwitch(props) {
	const { callback, name } = props
	return (
		<div className="toggle-switch">
			<input
				type="checkbox"
				className="toggle-switch-checkbox"
				name={name}
				id={name}
				onChange={(event) => {
					callback(event.target.checked)
				}}
			/>
			<label className="toggle-switch-label" htmlFor={name}>
				<span className="toggle-switch-inner" />
				<span className="toggle-switch-switch" />
			</label>
		</div>
	)
}

export default ToggleSwitch
