let isSigned = localStorage.getItem('login') || false
const initialState = { loggedIn: isSigned }

function loggedIn(state = initialState, action) {
	let nextState

	switch (action.type) {
		case 'LOGIN':
			nextState = {
				...state,
				loggedIn: true,
			}
			return nextState || state
		case 'LOGOUT':
			nextState = {
				...state,
				loggedIn: false,
			}
			return nextState || state
		default:
			return state
	}
}

export default loggedIn
