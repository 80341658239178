/** @format */

import React, { useEffect, useContext } from 'react';
import { GetFiche } from '../../API/Dentatete';
import { useParams } from 'react-router-dom';
import { UserContext } from '../../Context/UserContext';
import Login from '../Authentification/Login';
function FicheContent(props) {
	let { first, second, third, fourth, fifth } = useParams();
	let pathArray = [first, second, third, fourth, fifth];
	let path = '';

	const userContext = useContext(UserContext);
	for (let i = 0, c = pathArray.length; i < c; i++) {
		if (typeof pathArray[i] == 'undefined') {
			break;
		}
		if (i !== 0) path += '/';
		path += pathArray[i];
	}

	useEffect(() => {
		let contentDiv = document.querySelector('.content');
		let title = document.querySelector('.ficheContent h1');
		document.title = 'Fiches - DenTaTête';
		let publication = document.querySelector(
			'.ficheContent #publication_date'
		);
		if (!contentDiv) return;
		if (!path) return;
		GetFiche(path).then((fiche) => {
			if (!fiche?.content) return;
			document.title = fiche.name + ' - DenTaTête';
			if (!userContext.user) {
				console.log('user disconnected');
				let limitedContent = limitContent(fiche.content);
				contentDiv.innerHTML = '';
				contentDiv.append(limitedContent);
			} else {
				contentDiv.innerHTML = fiche.content;
			}
			publication.textContent = publicationDate(fiche);
			title.innerHTML = fiche.name;
		});
	});
	const showContent = () => {
		return (
			<UserContext.Consumer>
				{(userContext) => (
					<>
						{!userContext.user ? (
							<>
								<h1 className={'title'}>
									Selectionnez une fiche
								</h1>

								<p id="publication_date"></p>
								<div
									className={'limited-content content'}
								></div>
								<Login />
							</>
						) : (
							<>
								<h1 className={'title'}>
									Selectionnez une fiche
								</h1>

								<p id="publication_date"></p>
								<div className={'content'}></div>
							</>
						)}
					</>
				)}
			</UserContext.Consumer>
		);
	};

	return <div className={'ficheContent'}>{showContent()}</div>;
}

export default FicheContent;

function publicationDate(fiche) {
	let date_publication = date(fiche.date_publication);
	let date_update = date(fiche.date_update);
	let date_publicationStr = false;
	let date_updateStr = false;
	//let publication = document.createElement('p');
	//let reedition = document.createElement('p');

	if (date_publication) {
		let limitDate = new Date('09/01/2021');
		let Date2018 = new Date('01/01/2019');
		if (date_publication < limitDate) {
			date_publicationStr = 'en ' + date_publication.getFullYear();
		} else {
			date_publicationStr =
				'Publiée le ' + date_publication.toLocaleDateString('fr-FR');
		}
		if (date_publication < Date2018) {
			date_publicationStr =
				'Fiche en cours de réédition. Publiée ' + date_publicationStr;
		}
	}

	if (date_update) {
		date_updateStr = date_update.toLocaleDateString('fr-FR');
	}

	if (date_publication && date_update && date_update > date_publication) {
		return date_publicationStr + ', modifiée le ' + date_updateStr + '.';
	} else if (date_publication) {
		return date_publicationStr + '.';
	} else {
		return 'Fiche en cours de réédition.';
	}
}

function date(dateStr) {
	var date;
	if (dateStr == null) {
		return false;
	}
	if (dateStr.match('Z') && dateStr.match('T')) {
		date = new Date(dateStr);
	} else {
		var dateStrUTC = dateStr.replace(' ', 'T') + 'Z';
		date = new Date(dateStrUTC);
	}

	if (date === 'Invalid Date') {
		return false;
	}
	return date;
}
function limitContent(data) {
	const content = document.createElement('div');
	var contentModified = document.createElement('div');
	content.innerHTML = data;
	contentModified.innerHTML = data;
	contentModified.querySelector('.padding').innerHTML = '';
	let padding = content.querySelector('.padding');
	let numberTitle = 0;
	for (var i = 0, c = padding.childNodes.length; i < c; i++) {
		let child = padding.childNodes[i];
		if (child.nodeType == 1) {
			if (child.id == 'break') break;
			let clone = child.cloneNode(true);
			contentModified.querySelector('.padding').append(clone);
		}
	}
	return contentModified;
}
