import React from 'react'
import ReactDOM from 'react-dom'
import 'typeface-roboto'
import './index.scss'
import App from './Content/App'
import { Provider } from 'react-redux'
import Store from './Store/configureStore'
import reportWebVitals from './reportWebVitals'

ReactDOM.render(
	<React.StrictMode>
		<Provider store={Store}>
			<App />
		</Provider>
	</React.StrictMode>,
	document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
