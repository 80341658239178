/** @format */
import { useParams } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { ConfirmEmailAPI } from '../../API/Dentatete';
function ConfirmEmail(props) {
	const { code, email } = useParams();
	const [error, setError] = useState(false);
	const data = { code, email };
	useEffect(() => {
		window.scrollTo(0, 0);
	});
	useEffect(() => {
		document.title = "Confirmation de l'adresse email - DenTaTête";
	});

	useEffect(() => {
		if (!data) return;
		if (error) return;
		ConfirmEmailAPI(data).then((res) => {
			setError(res?.error);
		});
	});

	const showResult = (error) => {
		switch (error) {
			case 'none':
				return <p className="success">Adresse e-mail confirmée!</p>;
			case 'code':
				return <p className="error">Code de validation erroné!</p>;
			default:
				return <p>Validation en cours ...</p>;
		}
	};
	return (
		<article className="wrapper">
			<div className="compte">{error ? showResult(error) : null}</div>
		</article>
	);
}

export default ConfirmEmail;
