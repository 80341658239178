import Logo from './Logo'
import fiche from '../../Assets/screens/fiche.png'
import DownloadButton from '../Buttons/DownloadButton'
import Pricing from '../Subscription/Pricing'
import PhoneMockup from '../Others/PhoneMockup'
import Feature from './Feature'
import Separator from '../Home/Separator'
function Section(props) {
	const type = props.type
	switch (type) {
		case 'main':
			return (
				<section className={type}>
					<div className="wrapper">
						<div className="subsection">
							<Logo />
							<h1>
								L'application pour les étudiants en dentaire
							</h1>
							<p>
								Fiches cliniques, recommandations des sociétés
								savantes, informations sur les biomatériaux. Sur
								DenTaTête, retrouvez tout ce dont vous aurez
								besoin pour votre pratique quotidienne!
							</p>
							<DownloadButton store={'apple'} />
							<DownloadButton store={'google'} />
						</div>
						<div className="subsection hidden-sm">
							<PhoneMockup img={'fiche'} />
						</div>
					</div>
					<span className="circle main"></span>
				</section>
			)
		case 'second':
			return (
				<>
					<section className={type + ' hidden-lg'}>
						<div className="wrapper">
							<PhoneMockup img={'recos'} />
						</div>
					</section>
					<section className={type}>
						<div className="wrapper">
							<div className="subsection  hidden-sm">
								<PhoneMockup img={'recos'} />
								<span className="circle accent"></span>
							</div>
							<div className="subsection">
								<Feature
									title={'Fiches cliniques'}
									text={
										'Vous y trouverez des protocoles opératoires détaillés étapes par étapes, des fiches cliniques sur les pathologies buccales et les recommantations des sociétés savantes'
									}
									img={'fiche'}
								/>
								<Feature
									title={'Plateaux Techniques'}
									text={
										'Vous y trouverez les listes des instruments et biomatériaux nécessaire à la réalisation des différents actes'
									}
									img={'plateaux'}
								/>

								<Feature
									title={'Outils'}
									text={
										'Contient plusieurs outils utiles comme le calcul de posologie en fonction du poids'
									}
									img={'outils'}
								/>
							</div>
						</div>
					</section>
				</>
			)
		case 'third':
			return (
				<section className={type}>
					<div className="wrapper pricingDiv">
						<h2>
							Commencez dès aujourd'hui avec une semaine d'essai!
						</h2>
						<div>
							<Pricing type={'etudiant'} />
							<Pricing type={'dentiste'} />
						</div>
					</div>
				</section>
			)
		case 'first-separator':
			return (
				<section className={type}>
					<div className="wrapper">
						<Separator
							text={
								'Tout ce dont vous avez besoin au même endroit!'
							}
						/>
					</div>
				</section>
			)
		case 'second-separator':
			return (
				<section className={type}>
					<div className="wrapper">
						<Separator
							text={
								"Commencez dès aujourd'hui avec un mois d'essai!"
							}
						/>
					</div>
				</section>
			)
		default:
			return (
				<section className={'main'}>
					<div className="wrapper">
						<div className="subsection">
							<Logo />
							<h1>Débutez votre pratique sereinement!</h1>
							<DownloadButton />
							<DownloadButton />
						</div>
						<div className="subsection">
							<img
								src={fiche}
								className={'screenshot'}
								alt={"Screen de l'application"}
							/>
						</div>
					</div>
				</section>
			)
	}
}

export default Section
