import React from 'react'
import '../Styles/Style.scss'
import Section from '../Components/Standard/Section'

class Home extends React.Component {
	componentDidUpdate() {
		window.scrollTo(0, 0)
	}
	componentDidMount() {
		window.scrollTo(0, 0)
		document.title = 'Accueil - DenTaTête';
	}
	render() {
		return (
			<div className="home">
				<Section type={'main'} />
{/* 				<Section type={'first-separator'} />
 */}				<Section type={'second'} />
				<Section type={'third'} />
			</div>
		)
	}
}
export default Home
