import Appstore from '../../Assets/Appstore.svg'
import Googleplay from '../../Assets/Googleplay.svg'
function DownloadButton(props) {
	const { store } = props
	let imgsrc = Appstore
	let link =
		'https://apps.apple.com/us/app/dent-ta-tête/id1381227975?l=fr&ls=1'
	let alt = "Télécharger sur l'AppStore"
	switch (store) {
		case 'apple':
			imgsrc = Appstore
			link =
				'https://apps.apple.com/us/app/dent-ta-tête/id1381227975?l=fr&ls=1'
			break
		case 'google':
			imgsrc = Googleplay
			link =
				'https://play.google.com/store/apps/details?id=com.denttatete.app'
			alt = 'Disponible sur le Play Store'
			break
		default:
			imgsrc = Appstore
			link =
				'https://apps.apple.com/us/app/dent-ta-tête/id1381227975?l=fr&ls=1'
	}

	return (
		<button
			className={'dl-btn'}
			onClick={(e) => {
				e.preventDefault()
				window.location.href = link
			}}
		>
			<img src={imgsrc} alt={alt} />
		</button>
	)
}

export default DownloadButton
