import fiche from '../../Assets/screens/fiche.png'
import recos from '../../Assets/screens/recos.png'
import plateaux from '../../Assets/screens/materiel.png'
import outils from '../../Assets/screens/prescription.png'
function PhoneMockup(props) {
	const img = props.img
	if (img === 'fiche') {
		return (
			<div className="phone-mockup">
				<div className="phone-inside">
					<img
						src={fiche}
						className={'screenshot'}
						alt={"Screen de l'application"}
					/>
				</div>
			</div>
		)
	}
	return (
		<div className="phone-mockup">
			<div className="phone-inside">
				<div className={'anim'}>
					<img
						src={recos}
						className={'screenshot'}
						alt={"Screen de l'application"}
					/>
					<img
						src={fiche}
						className={'screenshot'}
						alt={"Screen de l'application"}
					/>
					<img
						src={plateaux}
						className={'screenshot'}
						alt={"Screen de l'application"}
					/>
					<img
						src={outils}
						className={'screenshot'}
						alt={"Screen de l'application"}
					/>
				</div>
			</div>
		</div>
	)
}

export default PhoneMockup
