let hasAccess = localStorage.getItem('access') || 0
const initialState = { access: hasAccess }

function Access(state = initialState, action) {
	let nextState

	switch (action.type) {
		case 'SET_ACCESS':
			nextState = {
				...state,
				access: action,
			}
			return nextState || state
		case 'RESET_ACCESS':
			nextState = {
				...state,
				access: 0,
			}
			return nextState || state
		default:
			return state
	}
}

export default Access
