/** @format */

import Button from '../Buttons/Button';
import Input from '../Form/Input';
import Logo from '../Standard/Logo';
import Error from '../Others/Error';
import { LoginAPI } from '../../API/Dentatete';
import React from 'react';
import { UserContext } from '../../Context/UserContext';
import { Link } from 'react-router-dom';

class Login extends React.Component {
	constructor(props) {
		super(props);
		this.email = '';
		this.password = '';
		this.state = { error: false };
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		document.title = 'Connexion - DenTaTête';
	}

	handleInputChange = (event) => {
		const target = event.target;
		const value = target.value;
		const name = target.name;
		this[name] = value;
	};
	_submit = (event) => {
		event?.preventDefault();
		const auth = this.context;
		const email = this.email;
		const password = this.password;
		const formData = { email, password };
		LoginAPI(formData).then((data) => {
			if (data.error === 'none') {
				let user = {
					id: data.id,
					firstName: data.firstName,
					email: data.email,
				};
				auth.login(user);
				return;
			}
			this.setState({
				error: data.error,
			});
		});
	};
	render() {
		return (
			<section className={'auth'}>
				<div className={'wrapper'}>
					<form onSubmit={this._submit}>
						<Logo />
						<h1>Se connecter et accéder à toutes les fiches</h1>
						{this.state.error ? (
							<Error error={this.state.error} />
						) : null}
						<Input
							type="email"
							onChange={this.handleInputChange}
							autoComplete="email"
						/>
						<Input
							type="password"
							onChange={this.handleInputChange}
						/>
						<Button text={'Se Connecter'} callback={this._submit} />
						<p>
							<Link to={'/reinitialisation-mot-de-passe'}>
								Mot de passe oublié
							</Link>
							<br />
							<br />
							Je ne possède pas de compte,{' '}
							<Link to={'/inscription'}>m'inscrire</Link>.
						</p>
					</form>
				</div>
			</section>
		);
	}
}
Login.contextType = UserContext;

export default Login;
