/** @format */

import { FaPaperPlane, FaKey, FaUserAlt } from 'react-icons/fa';

function Input(props) {
	const { type, onChange, value } = props;
	switch (type) {
		case 'email':
			return (
				<label className={'input'}>
					<div className={'icon'}>
						<FaPaperPlane />
					</div>
					<input
						type={'email'}
						placeholder={'Votre e-mail'}
						autoComplete={'email'}
						name="email"
						onChange={(event) => {
							onChange(event);
						}}
						defaultValue={value || ''}
						readOnly={value || false}
					/>
				</label>
			);
		case 'firstName':
			return (
				<label className={'input'}>
					<div className={'icon'}>
						<FaUserAlt />
					</div>
					<input
						type={'text'}
						placeholder={'Prénom'}
						autoComplete={'given-name'}
						name="firstName"
						defaultValue={value || ''}
						onChange={(event) => {
							onChange(event);
						}}
						readOnly={value || false}
					/>
				</label>
			);
		case 'lastName':
			return (
				<label className={'input'}>
					<div className={'icon'}>
						<FaUserAlt />
					</div>
					<input
						type={'text'}
						placeholder={'Nom de Famille'}
						value={value}
						autoComplete={'family-name'}
						name="lastName"
						onChange={(event) => {
							onChange(event);
						}}
					/>
				</label>
			);

		case 'object':
			return (
				<label className={'input'}>
					<input
						type={'text'}
						placeholder={'Sujet ...'}
						autoComplete={'off'}
						name="object"
						onChange={(event) => {
							onChange(event);
						}}
					/>
				</label>
			);

		case 'message':
			return (
				<label className={'input'}>
					<textarea
						type={'text'}
						placeholder={'Votre message ...'}
						autoComplete={'off'}
						name="message"
						onChange={(event) => {
							onChange(event);
						}}
					/>
				</label>
			);
		case 'password':
			return (
				<label className={'input'}>
					<div className={'icon'}>
						<FaKey />
					</div>
					<input
						name="password"
						type={'password'}
						autoComplete={'new-password'}
						placeholder={'Votre mot de passe'}
						onChange={(event) => {
							onChange(event);
						}}
					/>
				</label>
			);

		default:
			return (
				<label className={'input'}>
					<FaKey />
					<input
						type={'password'}
						autoComplete={'new-password'}
						placeholder={'Votre mot de passe'}
						onChange={onChange}
					/>
				</label>
			);
	}
}

export default Input;
