/** @format */

import LeftNavButton from '../Buttons/LeftNavButton';
import menu from '../../Assets/menu/menu';
import {
	prothese_complete,
	dentisterie_restauratrice,
	endodontie,
	urgences_endodontie,
	urgences_parodontie,
	urgences_chirurgie_bucale,
	odontologie_pediatrique,
	risques_cardio_vasculaire,
	risques_endocriniens,
	risques_traitements_medicaux,
	radiologie,
	algies_faciales,
	risques_hematologiques
} from '../../Assets/menu/index';
import { useSelector } from 'react-redux';
import React from 'react';
import '../../Styles/components/_leftnav.scss';
function LeftNavSmall(props) {
	let { showLesson, show } = props;
	const [lastMenu, setLastMenu] = React.useState(menu);
	let menus = {
		prothese_complete,
		dentisterie_restauratrice,
		endodontie,
		urgences_endodontie,
		urgences_parodontie,
		urgences_chirurgie_bucale,
		odontologie_pediatrique,
		risques_cardio_vasculaire,
		risques_endocriniens,
		risques_traitements_medicaux,
		radiologie,
		algies_faciales,
		risques_hematologiques
	};
	const access = useSelector((state) => state.Access.access);
	const showTree = (tree) => {
		return tree.map((subTree) => {
			if (subTree?.children?.length > 0) {
				if (subTree.access > access) {
					return (
						<li key={subTree.id}>
							<LeftNavButton
								item={subTree}
								showLesson={showLesson}
								disabled={true}
							/>
						</li>
					);
				}
				return (
					<li
						key={subTree.id}
						onClick={() => {
							setLastMenu(subTree.children);
						}}
					>
						<LeftNavButton
							item={subTree}
							showLesson={showLesson}
							disabled={false}
						/>
					</li>
				);
			}

			if (subTree?.child) {
				if (subTree.access > access) {
					return (
						<li key={subTree.id}>
							<LeftNavButton
								item={subTree}
								showLesson={showLesson}
								disabled={true}
							/>
						</li>
					);
				}
				return (
					<li
						key={subTree.id}
						onClick={() => {
							setLastMenu(menus[subTree.child]);
						}}
					>
						<LeftNavButton
							item={subTree}
							showLesson={showLesson}
							disabled={false}
						/>
					</li>
				);
			}

			if (subTree.distant_path) {
				if (subTree.access > access) {
					return (
						<li key={subTree.id}>
							<LeftNavButton item={subTree} disabled={true} />
						</li>
					);
				}
				return (
					<li key={subTree.id}>
						<LeftNavButton
							item={subTree}
							showLesson={showLesson}
							disabled={false}
						/>
					</li>
				);
			}
			return <></>;
		});
	};
	let className = 'left-nav ' + show;
	return (
		<>
			<nav className={className}>
				<ul>
					{menu !== lastMenu ? (
						<button
							className={'left-nav-btn'}
							onClick={() => {
								setLastMenu(menu);
							}}
						>
							Retour
						</button>
					) : null}

					{showTree(lastMenu)}
				</ul>
			</nav>
		</>
	);
}

export default LeftNavSmall;
