/** @format */

import React, { useContext } from 'react';
import Header from '../Components/Standard/Header';
import Footer from '../Components/Standard/Footer';
import CGU from '../Components/Legal/CGU';
import Home from './Home';
import Fiches from '../Components/Fiches/Fiches';
import Contact from '../Components/Standard/Contact';
import Login from '../Components/Authentification/Login';
import Signup from '../Components/Authentification/Signup';
import Recommandations from './Recommandations';
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Redirect,
} from 'react-router-dom';
import PolitiqueConfidentialite from '../Components/Legal/PolitiqueConfidentialite';
import { UserContext } from '../Context/UserContext';
import ConfirmationEmail from '../Components/Compte/ConfirmEmail';
import ConfirmNewPassword from '../Components/Compte/ConfirmNewPassword';
import ResetPassword from '../Components/Authentification/ResetPassword';
import ChangeEmail from '../Components/Compte/ChangeEmail';

class App extends React.Component {
	constructor(props) {
		super(props);
		this.login = (user) => {
			if (!user?.firstName) return;
			if (!user.email) return;
			if (!user?.id) return;
			this._saveUser(user);
			this.setState((user) => ({
				user,
			}));
		};
		this.logout = () => {
			this.setState(() => ({
				user: false,
			}));
			localStorage.setItem('user', false);
		};

		this.state = {
			user: this._getUser(),
			login: this.login,
			logout: this.logout,
		};
	}
	componentDidMount() {
		window.scrollTo(0, 0);
	}
	_saveUser = (user) => {
		localStorage.setItem('user', JSON.stringify(user));
	};
	_getUser = () => {
		try {
			return JSON.parse(localStorage.getItem('user'));
		} catch (e) {
			return false;
		}
	};
	render() {
		return (
			<UserContext.Provider value={this.state}>
				<Router>
					<Header />
					<Switch>
						<Route path="/fiches">
							<Fiches />
						</Route>
						<Route path="/recommandations">
							<Recommandations />
						</Route>
						<Route path="/CGU">
							<CGU />
						</Route>
						<Route path="/Politique-Confidentialite">
							<PolitiqueConfidentialite />
						</Route>
						<PublicRoute path="/connection">
							<Login />
						</PublicRoute>
						<PublicRoute path="/inscription">
							<Signup />
						</PublicRoute>
						<Route path="/contact">
							<Contact />
						</Route>
						<Route
							path={`/confirmation-email/:email/:code`}
							component={ConfirmationEmail}
						/>
						<Route
							path={`/changer-email/:email/:code`}
							component={ChangeEmail}
						/>
						<Route
							path={`/reinitialisation-mot-de-passe/:email/:code`}
							component={ConfirmNewPassword}
						/>
						<Route
							path={`/reinitialisation-mot-de-passe`}
							component={ResetPassword}
						/>
						<Route path="/">
							<Home />
						</Route>
					</Switch>

					<Footer />
				</Router>
			</UserContext.Provider>
		);
	}
}
function PrivateRoute({ children, ...rest }) {
	let auth = useContext(UserContext);
	return (
		<Route
			{...rest}
			render={({ location }) =>
				auth.user ? (
					children
				) : (
					<Redirect
						to={{
							pathname: '/connection',
							state: { from: location },
						}}
					/>
				)
			}
		/>
	);
}

function PublicRoute({ children, ...rest }) {
	let auth = useContext(UserContext);
	return (
		<Route
			{...rest}
			render={({ location }) =>
				!auth.user ? (
					children
				) : (
					<Redirect
						to={{
							pathname: '/fiches',
							state: { from: location },
						}}
					/>
				)
			}
		/>
	);
}

export default App;
