import { configureStore } from '@reduxjs/toolkit'
import loggedIn from './Reducers/loggedIn'
import Access from './Reducers/Access'
import Navigation from './Reducers/Navigation'

export default configureStore({
	reducer: {
		Navigation,
		loggedIn,
		Access,
	},
})
