const initialState = { page: 'Home' }

function Navigation(state = initialState, action) {
	let nextState

	switch (action.type) {
		case 'CHANGE_PAGE':
			nextState = {
				...state,
				page: action.value,
			}
			return nextState || state
		default:
			return state
	}
}

export default Navigation
