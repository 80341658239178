/** @format */

//import logo from '../../Assets/logo.png';
function Logo(props) {
	const { clear } = props;
	const logoClear = 'https://assets.dentatete.fr/logo_clear.png';
	const logo = 'https://assets.dentatete.fr/logo.png';
	let logoSrc = logo;
	if (clear) logoSrc = logoClear;
	return (
		<div className="logo">
			<img src={logoSrc} alt={'Website Logo'} />
		</div>
	);
}

export default Logo;
