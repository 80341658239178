import React from 'react'

function Error(props) {
	const {error} = props;
	let text = "Une erreur inconnue s'est produite.";
	switch (error){
		case 'loginError':
			text = 'Email et/ou mot de passe incorrect(s).'
		break;
		case 'inactive':
			text = "La validation de l'adresse email n'a pas été effectuée. Un email vous a été envoyé pour valider votre adresse."
		break;
		case 'missing':
			text = 'Veuillez renseigner tous les champs demandés.'
		break;
		case 'taken':
			text = 'Adresse e-mail déjà utilisée par un autre compte.'
		break;
		case 'resetPasswordCodeError':
			text = 'Le code de réinitialisation utilisé est incorrect.'
		break;
		default:
			text = "Une erreur inconnue s'est produite."

	}
	return(
		<p className='error'>
			{text}
		</p>
	)
}

export default Error;
