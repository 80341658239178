/** @format */
import React, { useEffect } from 'react';

function PolitiqueConfidentialite(props) {
	useEffect(() => {
		window.scrollTo(0, 0);
	});
	useEffect(() => {
		document.title = 'Politique de Confidentialité - DenTaTête';
	});
	return (
		<article className="wrapper">
			<div className="recos">
				<h1>Politique de Confidentialité</h1>
				<h2>Introduction</h2>
				<p>
					Lors de votre inscription sur la Plateforme, vous êtes
					amenés à nous communiquer des données personnelles vous
					concernant. Nous vous remercions de votre confiance et
					tenons à vous informer ici sur l’utilisation de ces données
					ainsi que sur les droits des personnes concernées. Le
					responsable de traitement des données à caractère personnel
					est l'Éditeur dont vous trouverez les coordonnées dans les
					mentions légales.
				</p>
				<p>
					La présente politique de protection des données personnelles
					(ci-après dénommé la « Politique ») a pour objet :
					d’encadrer l’utilisation qui est faite des données à
					caractère personnel vous concernant (ci-après dénommé les «
					Données Personnelles ») et de vous informer sur les
					traitements que nous effectuons de vos Données Personnelles
					à l’occasion de votre utilisation de la Plateforme.
				</p>
				<h2>1. Définitions</h2>
				<p>
					Les termes et expressions identifiés par une majuscule dans
					le cadre des présentes ont la signification mentionnée
					ci-après :
				</p>
				<ul>
					<li>
						« Éditeur » : désigne l'Éditeur tel qu'il est définit
						dans les Conditions Générales d'Utilisation disponible
						sur le site en pied de page.
					</li>

					<li>
						« Cookies » : désigne un fichier texte stocké dans le
						terminal d’un Utilisateur et qui sert à stocker des
						informations le concernant.
					</li>

					<li>
						« Données Personnelles » : désigne les données à
						caractère personnel permettant d’identifier directement
						ou indirectement l’Utilisateur ou l’un de ses employés,
						salariés, agents ou représentants lors de son
						inscription sur la Plateforme et de la création de son
						Compte (ex : nom, prénoms, adresse de courrier
						électronique, adresse postale, nom d’utilisateur,
						adresse IP, numéro RPPS, etc.).
					</li>

					<li>
						« Règlementation sur les Données Personnelles » :
						désigne ensemble le Règlement européen n°2016 – 679 du
						27 avril 2016 relatif à la protection des personnes
						physiques à l’égard du traitement des données à
						caractère personnel et à la libre circulation de ces
						données (ci-après, le « RGPD ») et les lois locales
						relatives à la protection des données à caractère
						personnel (en France : notamment la loi n°78-17 du 6
						janvier 1978 relative à l’informatique, aux fichiers et
						aux libertés, dans sa version modifiée).
					</li>

					<li>
						« Vous » ou « Utilisateur(s) » : désigne toute personne,
						physique ou morale, de droit privé ou de droit public,
						se connectant à la Plateforme par le biais de son
						Compte.
					</li>
				</ul>
				<p>
					Les termes non définis dans la présente Politique de
					Confidentialité auront la même signification que dans les
					Conditions Générales d’Utilisation de la Plateforme.
				</p>
				<h2>
					2. Informations relatives aux traitements de données
					personnelles mis en oeuvre par l'éditeur
				</h2>
				<h3>2.1 Collecte des données personnelles</h3>
				<p>
					L’Editeur est le responsable du traitement des données à
					caractère personnel collectées auprès des Utilisateurs.
				</p>
				<p>
					Dans le cadre de la mise à disposition de la Plateforme,
					l’Editeur est susceptible de collecter les Données
					Personnelles suivantes :
				</p>
				<ul>
					<li>
						Lors de la navigation sur la Plateforme : horaires et
						date de connexion, historique de navigation, navigateurs
						utilisés, parcours de l’Utilisateur.
					</li>
					<li>
						Lors de la création d'un Compte : les nom, prénoms,
						adresse email, spécialité, langue, numéro RPPS pour les
						professionnels, Carte CPS pour les professionnels, carte
						d’identité, certificat de scolarité pour les étudiants,
						les coordonnées bancaires.
					</li>
				</ul>
				<h3>2.2 Finalités de traitement des données personnelles</h3>
				<p>
					L’Editeur utilise les données personnelles des Utilisateurs
					pour différentes finalités, notamment les suivantes :
				</p>
				<h4>Navigation sur la Plateforme</h4>
				<p>
					Les données personnelles sont traitées afin de permettre de
					faciliter la navigation sur la Plateforme.
				</p>
				<p>
					La base juridique pour ce traitement est l’intérêt légitime
					de l’Editeur.
				</p>
				<h4>Création d’un Compte</h4>
				<p>
					Les données personnelles sont traitées afin de permettre la
					création d’un Compte.
				</p>
				<p>
					La base juridique pour ce traitement est l’exécution d’un
					contrat entre l’Editeur et l’Utilisateur.
				</p>
				<h4>Paiement et facturation</h4>
				<p>
					Les données personnelles sont collectées afin de permettre
					le paiement et la facturation des frais d’Abonnement. La
					base juridique pour ce traitement est l’exécution d’un
					contrat entre l’Editeur et l’Utilisateur.
				</p>
				<h4>Pour toute requête/communication adressée à l’Editeur</h4>
				<p>
					Les données personnelles sont traitées afin de permettre :
				</p>
				<ul>
					<li>
						la gestion des questions et requêtes adressées à
						l’Editeur ;
					</li>
					<li>
						l’exercice des droit désignés à l’article 6 ci-après ;
					</li>
					<li>
						l’exercice de tout signalement conformément à l’article
						11 des Conditions Générales d’Utilisation de la
						Plateforme.
					</li>
				</ul>
				<h3>2.3 Les destinataires des Données Personnelles</h3>
				<p>
					Les Données Personnelles de l’Utilisateur collectées via les
					formulaires présents sur la Plateforme et les documents
					collectés sont destinés à l'Éditeur afin d’accomplir les
					finalités ci-dessus énoncées.
				</p>
				<p>
					Les Données Personnelles sont également susceptibles d’être
					rendues accessibles aux prestataires informatiques que
					l’Éditeur utilise pour le fonctionnement et l’hébergement de
					la Plateforme.
				</p>
				<p>
					En outre, l’Éditeur peut être amené à transmettre des
					données à des tiers lorsqu’il reçoit la requête d’une
					autorité judiciaire ou de toute autorité administrative
					habilitée par la loi sollicitant la communication de ces
					informations conformément aux dispositions législatives en
					vigueur.
				</p>
				<h3>2.4 Conservation des données personnelles</h3>
				<p>
					Les Données Personnelles collectées de l’Utilisateur par
					l’Editeur sont conservées tant que le Compte de
					l’Utilisateur est actif puis sont ensuite archivées pendant
					5 ans à compter de la résiliation de l’Abonnement et de la
					fermeture du Compte.
				</p>
				<p>
					Lorsqu’un Compte est resté inactif pendant une durée
					supérieure à 2 ans, l’Editeur avertira l’Utilisateur et
					supprimera les Comptes de ceux qui n’auraient pas réagi dans
					un certain délai.
				</p>
				<p>
					Dans certaines circonstances comme le suivi d’un
					contentieux, les Données Personnelles sont conservées
					pendant la durée de la procédure.
				</p>
				<p>
					Les Cookies utilisés par l’Editeur sont conservés pendant 13
					mois.
				</p>
				<h2>3. Cookies</h2>
				<p>
					Lorsque vous naviguez sur la Plateforme, certaines
					informations peuvent également être collectées de façon
					passive, y compris votre adresse de protocole Internet
					(adresse IP), type de navigateur, vos noms de domaine, vos
					heures d’accès, votre système d’exploitation, les
					défilements d’écran. L’Editeur de la Plateforme utilise
					également des Cookies et des données de navigation comme des
					localisateurs de ressources uniformes (URL) pour recueillir
					des informations concernant l’heure et la date de votre
					connexion à la Plateforme ainsi que les solutions et
					informations que vous avez cherchées et consultées.
				</p>
				<p>
					Ce type d’information est collecté afin d’analyser
					l’utilisation de nos services et solutions et de les rendre
					plus utiles pour vous. Une « adresse de protocole Internet »
					ou « Adresse IP » est un nombre qui est automatiquement
					attribué à votre terminal lorsque vous utilisez Internet.
					Dans certains cas, votre Adresse IP reste la même d’une
					session de navigation à une autre ; mais si vous utilisez un
					fournisseur d’accès Internet grand public, votre Adresse IP
					sera probablement différente d’une session à l’autre. Nous
					suivons les Adresses IP seulement conjointement avec des
					Cookies de session pour analyser notre flux de page Web.
				</p>
				<p>
					En fonction des circonstances, ces données peuvent permettre
					de tirer des conclusions concernant certains Utilisateurs de
					la Plateforme. L’Editeur peut se servir de ces données afin
					d’analyser des tendances ou d’établir des statistiques.
					Toutefois, aucune donnée personnelle n´est utilisée dans ce
					contexte. Ces données sont utilisées uniquement après avoir
					été rendues anonymes. Nous vous informons que vous pouvez
					vous opposer à l’enregistrement de ces Cookies dans les
					conditions décrites à l’article 4 ci-dessous.
				</p>
				<h2>4. Les choix de l'utilisateur concernant les cookies</h2>
				<h3>4.1 Le choix depuis le site dentatete.fr</h3>
				<p>
					L'Éditeur informe à toutes personnes naviguant sur le site
					internet [dentatete.fr](http://dentatete.fr) que
					l’enregistrement des informations précitées à l’article 3 se
					fera qu’après que l’utilisateur ait librement donné son
					consentement, à savoir un acte positif. Pour cela,
					l’utilisateur disposera d’un bouton lui permettant
					d’accepter ou de refuser l’enregistrement de ces Cookies. Il
					est nécessaire de souligner que si aucun acte positif n’a
					été effectué par l’utilisateur en vue d’autoriser
					l’enregistrement de ces Cookies pendant un laps de temps,
					cela sera interprété comme étant un refus.
				</p>
				<p>
					dentatete.fr met à votre disposition un outil simple qui
					vous permet de paramétrer le dépôt de cookies. Les choix que
					vous ferez grâce à cet outil ne sont valables que dans le
					cadre de votre navigation sur la plateforme
					dentatete.fr. Pour utiliser cet outil, Cliquer ici
				</p>
				<h3>4.2 Le choix sur votre navigateur</h3>
				<p>
					Tout paramétrage que vous pouvez entreprendre pour gérer les
					Cookies sera susceptible de modifier votre navigation sur
					Internet et vos conditions d’accès à certains services
					nécessitant l’utilisation de Cookies.
				</p>
				<p>
					Vous pouvez configurer votre logiciel de navigation de
					manière à ce que des Cookies soient enregistrés dans votre
					terminal ou, au contraire, qu’ils soient rejetés, soit
					systématiquement, soit selon leur émetteur. Vous pouvez
					également configurer votre logiciel de navigation de manière
					à ce que l’acceptation ou le refus des Cookies vous soient
					proposés ponctuellement, avant qu’un Cookie soit susceptible
					d’être enregistré dans votre terminal.
				</p>
				<p>
					Si vous refusez l’enregistrement des Cookies dans votre
					terminal, ou si vous supprimez ceux qui y sont enregistrés,
					il est possible que vous ne puissiez plus bénéficier de
					certaines fonctionnalités qui sont néanmoins nécessaires
					pour utiliser la Plateforme. Tel serait le cas si vous
					tentiez d’accéder à nos contenus ou services qui nécessitent
					de vous identifier. Tel serait également le cas lorsque nous
					– ou nos prestataires – ne pourrions pas reconnaître, à des
					fins de comptabilité technique, le type de navigateur
					utilisé par votre terminal, ses paramètres de langue et
					d’affichage ou le pays depuis lequel votre terminal semble
					connecté à Internet.
				</p>
				<p>
					Le cas échéant, nous déclinons toute responsabilité pour les
					conséquences liées au fonctionnement dégradé de nos services
					résultant de l’impossibilité pour nous d’enregistrer ou de
					consulter les Cookies nécessaires à leur fonctionnement et
					que vous auriez refusés ou supprimés.
				</p>
				<p>
					Tous les navigateurs Internet modernes permettent de changer
					les réglages relatifs aux Cookies. Ces réglages sont
					habituellement disponibles dans le menu « outils », «
					options », « aide » ou « préférences » de votre navigateur.
				</p>
				<h3>4.3 Traceurs exemptés du recueil du consentement</h3>
				<p>
					Les utilisateurs sont informés que les traceurs suivants
					peuvent, notamment, être regardés comme exemptés :
				</p>
				<ul>
					<li>
						les traceurs conservant le choix exprimé par les
						utilisateurs sur le dépôt de traceurs
					</li>
					<li>
						les traceurs destinés à l’authentification auprès d’un
						service, y compris ceux visant à assurer la sécurité du
						mécanisme d’authentification, par exemple en limitant
						les tentatives d’accès robotisées ou inattendues
					</li>
					<li>
						les traceurs destinés à garder en mémoire le contenu
						d’un panier d’achat sur un site marchand ou à facturer à
						l’utilisateur le ou les produits et/ou services achetés
					</li>
					<li>
						les traceurs de personnalisation de l’interface
						utilisateur (par exemple, pour le choix de la langue ou
						de la présentation d’un service), lorsqu’une telle
						personnalisation constitue un élément intrinsèque et
						attendu du service
					</li>
					<li>
						les traceurs permettant l’équilibrage de la charge des
						équipements concourant à un service de communication
					</li>
					<li>
						les traceurs permettant aux sites payants de limiter
						l’accès gratuit à un échantillon de contenu demandé par
						les utilisateurs (quantité prédéfinie et/ousur une
						période limitée)
					</li>
					<li>
						Les traceurs de mesure d’audience: La gestion d’un site
						web ou d’une application requiert presque
						systématiquement l’utilisation de statistiques de
						fréquentation et/ou de performance. Ces mesures sont
						dans de nombreux cas indispensables au bon
						fonctionnement du site ou de l’application et donc à la
						fourniture du service. Ces traceurs auront une durée de
						vie de treize mois et ne seront pas prorogées
						automatiquement lors de nouvelles visites. Les
						informations qui seront collectées par l’intermédiaire
						de ces traceurs seront conservées pour une durée
						maximale de vingt-cinq mois.
					</li>
				</ul>
				<h2>5. Mesures de sécurité</h2>
				<p>
					L’Editeur met en place des mesures de sécurité techniques,
					organisationnelles et physiques appropriées afin de protéger
					les données personnelles traitées contre les dommages,
					pertes, détournements, intrusions, divulgations, altérations
					ou destructions ou l’accès non autorisé à de telles données,
					de manière accidentelle ou illicite.
				</p>
				<p>
					Les systèmes informatiques de l’Editeur font l’objet d’une
					protection physique et logicielle conforme à l’état de l’art
					et des procédures de sauvegarde physique et électronique des
					données sont mises en œuvre.
				</p>
				<h2>6. Droits des personnes sur les données collectées</h2>
				<p>
					Conformément au RGPD, chaque Utilisateur dispose des
					droits :
				</p>
				<ul>
					<li>
						de demander l’accès à l’ensemble de ses Données
						Personnelles ;
					</li>
					<li>
						de demander la rectification, la mise à jour ou
						l’effacement de ses Données Personnelles ;
					</li>
					<li>de demander à exercer son droit d’opposition ;</li>
					<li>
						de demander la portabilité de ses Données Personnelles
						dans un format ouvert et lisible par machine ;
					</li>
					<li>de demander une limitation des traitements;</li>
					<li>
						de formuler des directives post-mortem concernant la
						conservation, l’effacement et la communication de ses
						données personnelles après son décès. Ces directives
						peuvent en outre être enregistrées auprès d’un tiers de
						confiance numérique certifié et désigner une personne
						chargée de leur exécution.
					</li>
				</ul>
				<p>
					A défaut, les héritiers sont désignés et peuvent demander
					d’accéder aux traitements de données permettant
					l’organisation et le règlement de la succession du défunt
					et/ou de faire procéder à la clôture du compte de la
					personne concernée sur le Plateforme et/ou de s’opposer aux
					traitements des données de la personne concernée.
				</p>
				<p>
					L’Utilisateur peut indiquer, à tout moment, qu’elle ne
					souhaite pas, en cas de décès, que ses données personnelles
					soient communiquées à un tiers.
				</p>
				<p>
					Ces droits peuvent être exercés directement par courrier
					électronique à l’adresse : contact@dentatete.fr
				</p>
				<p>
					Une réponse sera adressée à l’Utilisateur dans un délai d’un
					mois maximum suivant la date de réception de la demande.
				</p>
				<p>
					Pour toute autre réclamation, chaque Utilisateur dispose du
					droit de saisir la Commission Nationale de l’Informatique et
					des Libertés (ci-après, « la CNIL »).
				</p>
				<h2>7. Mise à jour de la politique</h2>
				<p>
					L’Editeur est susceptible de modifier à tout moment la
					Politique. Si l’Editeur souhaite utiliser les Données
					Personnelles d’une manière différente de celle stipulée dans
					la Politique en vigueur au moment de la collecte, ces
					modifications seront publiées dans une nouvelle version de
					la présente Politique.
				</p>
				<h2>8. Questions/Contact</h2>
				<p>
					Pour toutes questions ou commentaires relatifs à la
					Politique ou à la façon dont l’Editeur procède à la collecte
					et à l’utilisation des données, vous pouvez vous adresser à
					l’Editeur :contact@dentatete.fr
				</p>
			</div>
		</article>
	);
}

export default PolitiqueConfidentialite;
