/** @format */

import Button from '../Buttons/Button';
import Input from '../Form/Input';
import Logo from '../Standard/Logo';
import { SignupAPI } from '../../API/Dentatete';
import Error from '../Others/Error';
import React from 'react';
import Checkbox from '../Form/Checkbox';
import { UserContext } from '../../Context/UserContext';

class Signup extends React.Component {
	constructor(props) {
		super(props);
		this.state = { disabled: 'disabled' };
		this.email = '';
		this.password = '';
		this.firstName = '';
		this.lastName = '';
		this.statut = false;
		this.politique = false;
		this.cgu = false;
		this.state = { error: false };
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		document.title = 'Inscription - DenTaTête';
	}
	handleInputChange = (event) => {
		const target = event.target;
		var value = target.value;
		if (target.type === 'checkbox') value = target.checked;
		const name = target.name;
		this[name] = value;
		if (this.cgu && this.politique && this.statut) {
			this.setState({ disabled: '' });
		} else {
			this.setState({ disabled: 'disabled' });
		}
	};
	_checkSubmit = (event) => {
		event?.preventDefault();
		if (!this.cgu) return;
		if (!this.politique) return;
		if (!this.statut) return;

		this._submit();
	};

	_submit = (event) => {
		event?.preventDefault();
		const auth = this.context;
		const email = this.email;
		const password = this.password;
		const firstName = this.firstName;
		const lastName = this.lastName;
		const statut = this.statut;
		const formData = { email, password, firstName, lastName, statut };
		SignupAPI(formData).then((data) => {
			console.log(data);
			if (data.error === 'none') {
				let user = {
					id: data.id,
					firstName: data.firstName,
					lastName: data.lastName,
					statut: data.statut,
					email: data.email,
				};
				auth.login(user);
				return;
			}
			this.setState({
				error: data.error,
			});
		});
	};
	render() {
		return (
			<section className={'auth'}>
				<div className={'wrapper'}>
					<form onSubmit={this._submit}>
						<Logo />
						<h1>S'inscrire et accéder à toutes les fiches</h1>
						{this.state.error ? (
							<Error error={this.state.error} />
						) : null}
						<Input
							type="firstName"
							onChange={this.handleInputChange}
							autoComplete="given-name"
						/>
						<Input
							type="lastName"
							onChange={this.handleInputChange}
							autoComplete="family-name"
						/>
						<Input
							type="email"
							onChange={this.handleInputChange}
							autoComplete="email"
						/>
						<Input
							type="password"
							onChange={this.handleInputChange}
						/>
						<Checkbox
							type="cgu"
							onChange={this.handleInputChange}
						/>
						<Checkbox
							type="politique"
							onChange={this.handleInputChange}
						/>
						<Checkbox
							type="etudiant"
							onChange={this.handleInputChange}
						/>
						<Checkbox
							type="dentiste"
							onChange={this.handleInputChange}
						/>
						<Button
							text={"S'inscrire"}
							callback={this._checkSubmit}
							disabled={this.state.disabled}
						/>
						<p>
							Je possède déjà un compte,{' '}
							<a href="/connection">me connecter</a>.
						</p>
					</form>
				</div>
			</section>
		);
	}
}
Signup.contextType = UserContext;

export default Signup;
