/** @format */

import { Switch, Route, useRouteMatch } from 'react-router-dom';
import React, { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import DownloadButton from '../Buttons/DownloadButton';
import LeftNav from './LeftNav';
import LeftNavSmall from './LeftNavSmall';
import FicheContent from './FicheContent';

function Fiches(props) {
	let { path } = useRouteMatch();
	useEffect(() => {
		window.scrollTo(0, 0);
	});
	if (!isMobile) {
		return (
			<div className="fiches">
				<LeftNavSmall show={'hidden-lg'} />
				<LeftNav show={'hidden-sm'} />
				<Switch>
					<Route exact path={path}>
						<FicheContent />
					</Route>
					<Route
						path={`${path}/:first/:second/:third/:fourth/:fifth`}
						component={FicheContent}
					/>
					<Route
						path={`${path}/:first/:second/:third/:fourth`}
						component={FicheContent}
					/>
					<Route
						path={`${path}/:first/:second/:third`}
						component={FicheContent}
					/>
					<Route
						path={`${path}/:first/:second`}
						component={FicheContent}
					/>
					<Route path={`${path}/:first`} component={FicheContent} />
				</Switch>
			</div>
		);
	}
	return (
		<div className="fiches">
			<LeftNavSmall show={'hidden-lg'} />
			<LeftNav show={'hidden-sm'} />
			<Switch>
				<Route exact path={path}>
					<div className="fiches mobile">
						<h1>
							Téléchargez l'application ou accédez au site depuis
							un ordinateur pour une meilleure ergonomie
						</h1>
						<div className="appStoresDiv">
							<DownloadButton store={'apple'} />
							<DownloadButton store={'google'} />
						</div>
					</div>
				</Route>
				<Route
					path={`${path}/:first/:second/:third/:fourth/:fifth`}
					component={FicheContent}
				/>
				<Route
					path={`${path}/:first/:second/:third/:fourth`}
					component={FicheContent}
				/>
				<Route
					path={`${path}/:first/:second/:third`}
					component={FicheContent}
				/>
				<Route
					path={`${path}/:first/:second`}
					component={FicheContent}
				/>
				<Route path={`${path}/:first`} component={FicheContent} />
			</Switch>
		</div>
	);
}

export default Fiches;
