/** @format */

function Button(props) {
	const { callback, text, type } = props;
	const disabled = props.disabled || '';
	let className = 'btn ' + type + ' ' + disabled;
	return (
		<button
			className={className}
			onClick={(event) => {
				if (disabled === '') callback(event);
			}}
		>
			{text}
		</button>
	);
}

export default Button;
