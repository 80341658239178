/** @format */

import Button from '../Buttons/Button';
import Feature from '../Standard/Feature';
import ToggleSwitch from '../Buttons/ToggleSwitch';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
function Pricing(props) {
	const { type } = props;
	let title, price;
	let [checked, setChecked] = React.useState(false);
	const history = useHistory();
	let mensuel,
		annuel,
		priceBreakdown = '';
	switch (type) {
		case 'etudiant':
			title = 'Etudiant';
			if (!checked) {
				price = '5,00 €';
				mensuel = 'selected';
				priceBreakdown = '5,00 € facturés mensuellement';
			}
			if (checked) {
				price = '3,00 €';
				annuel = 'selected';
				priceBreakdown = '36,00 € facturés anuellement';
			}
			break;
		case 'dentiste':
			title = 'Professionel';
			if (!checked) {
				price = '10,00 €';
				mensuel = 'selected';
				priceBreakdown = '10,00 € facturés mensuellement';
			}
			if (checked) {
				price = '6,00 €';
				annuel = 'selected';
				priceBreakdown = '72,00 € facturés anuellement';
			}
			break;
		default:
			title = 'Etudiant';
			price = '5,00 €';
			break;
	}
	const handleClick = useCallback((isChecked) => {
		setChecked(isChecked);
	}, []);
	const goToSubscription = () => {
		history.push('/inscription');
	};
	return (
		<div className={'pricing'}>
			<h3>{title}</h3>

			<div className={'duration'}>
				<p className={mensuel}>Mensuel</p>
				<ToggleSwitch name={type} callback={handleClick} />
				<p className={annuel}>Annuel</p>
			</div>
			<p className={'price'}>
				{price}
				<span className={'month'}>/mois</span>
			</p>
			<p className={'price-breakdown'}>{priceBreakdown} </p>
			<p className={'conditions'}>
				Sans engagement, résiliable à tout moment
			</p>

			<Button
				text={'Commencer avec 1 semaine offerte'}
				callback={goToSubscription}
			/>
			<Feature
				title={'Accès illimité à toutes les fiches'}
				img={'fiche'}
			/>
			<Feature
				title={'Accès illimité à tous les plateaux'}
				img={'plateaux'}
			/>
			<Feature
				title={'Ajouts et mises à jours régulières'}
				img={'outils'}
			/>
		</div>
	);
}

export default Pricing;
